/*Just add the class skeleton-loader and set the width/height to the elements you want to mask while loading the data*/
.skeleton-loader {
    display: block;
    background-color: #ededed;
    border-color: #bdc3c7;
    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 60px;
    width: 100%;
  }
  
  @keyframes skeletonAnimation {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.8;
    }
  }
  
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }